import React from "react";

function TypeB({
  className,
  headFontColor,
  headName,
  quoteContent,
  children,
  
  ...props
}) {
  return (
    <div
      {...props}
      className={`callout-wrapper cout-type-b ${className ? className : ""}`}
    >
       <h3
              style={{
                color: headFontColor,
              }}
              className="category-name"
            >
              {headName}
            </h3>

      {children}
            
      <div className="quote-container">     
            <div className="quote-content">{quoteContent ? <p  dangerouslySetInnerHTML={{ __html: quoteContent}} />: ""}</div>
      </div>
    </div>
  );
}

export default TypeB;
