import React  from "react";
import Layout from "layout";
import { Container, Row, Col } from "react-grid-system";
import useDeviceSize from "libs/hooks/useDeviceSize";
import Seo from "common/seo";
import heroImage from  "../images/eczema-hero-product.png";
import img from "../images/eczema/cout-advanced-repair-lotion-eczema.webp";
import img1 from "../images/eczema/cout-relief-cream-eczema.webp";
import imgOatmeal from "../images/icons/icon-oatmeal.png";
import imgCeramide from "../images/icons/icon-ceramide.png";
import imgLicochalcone from "../images/icons/icon-licoch.png";

import img4 from "../images/eczema/eczema-rash.webp";
import img5 from "../images/eczema/eczema-doctor-meet.webp";
import img6 from "../images/eczema/eczema-phscale.webp";
import img7 from "../images/eczema/eczema-prone-skin.webp";
import img8 from "../images/eczema/eczema-skin-barrier.webp";
import img9 from "../images/eczema/eczema-acid-mantle.webp";
import { TypeA, TypeB } from "common/callouts";

import icon1 from "../images/icons/icon-shower.webp";
import icon2 from "../images/icons/icon-bodywash.webp";
import icon3 from "../images/icons/icon-towel.webp";
import icon4 from "../images/icons/icon-rains.webp";
import icon5 from "../images/icons/icon-time.webp";
import icon6 from "../images/icons/icon-washhand.webp";
import icon7 from "../images/icons/icon-soap.webp";
import icon8 from "../images/icons/icon-steam.webp";

import {
	ProductTypeA,
	ProductTypeB,
	ProductTypeC,
} from "common/product-details";

import "./index.scss";

function HomePage() {
	const deviceSizes = useDeviceSize();

	return (
		<Layout pageClass="home">
			<Seo pageTitle="Eczema Relief"  pageDescription="Learn about Eucerin's therapeutic solutions for eczema" />

			
			{/* HERO SECTION */}
			<section className="fluid-section red-gradient-bg">
				<div className="inner-centered-container">
					<div className="hero-container">
					<Row>
						<Col md={12} lg={8} className={deviceSizes?.smUp ? "" : ""}  order={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
							<ProductTypeC
								productAlt="A tube of Eucerin Eczema Relief Cream and a bottle of Eczema Relief Cream body wash"
							>
								<div className="functions">
									<h4>Eucerin<sup>&reg;</sup> Eczema Relief Regimen:</h4>
									<h1>Skin pHriendly</h1>
									<p>
										<span className="mid-content">
										Gentle, pH-optimized, skin protectant formulations for eczema-prone skin. <nobr>For everyday</nobr> use by adults and children as young as 3 months.
										</span>
									</p>
									<ul className="checkmarks white">
										<li>
											<span>S T E R O I D - F R E E</span>
										</li>
										<li>
											<span>F R A G R A N C E - F R E E</span>
										</li>
										<li>
											<span>D Y E - F R E E</span>
										</li>
									</ul>
									<ul className="checkmarks white">
										<li>
											<span>P A R A B E N - F R E E</span>
										</li>
										<li>
											<span>N O N - C O M E D O G E N I C</span>
										</li>
										</ul>
								</div>
							</ProductTypeC>
						</Col>
						<Col md={12} lg={4} className={deviceSizes?.smUp ? "" : ""}  order={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
							<div className="cout-products">
								<img
									src={heroImage}
									alt="Products"
									className="hero-product"
								/>
							</div>
						</Col>
					</Row>
				</div>
				</div>
			</section>


			{/* LINK-BUTTON SECTION 1 */}
			<section className="inner-centered-container callout-with-links-bullets">
				<Container fluid>
					<Row>
						<Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
							<img src={img} alt="A bottle of Eczema Relief Cream & Body wash" className="" />
						</Col>
						<Col sm={12} md={9} className={deviceSizes?.smUp ? "no-pr" : ""}>
							<h2>
								Eucerin<sup>&reg;</sup> Eczema Relief Cream & <span class="fbreak">Body Wash</span>
							</h2>
							<div className="links-wrap">
								<span className="font-bold red-text">Buy now at:</span>
								<br />
								<ul className="link-target-button">
									<li>
										<a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Eczema-Relief-Cleanser-Eczema-prone/dp/B08BB5XH8J/ref=asc_df_B08BB5XH8J/?tag=hyprod-20&linkCode=df0&hvadid=507695844407&hvpos=&hvnetw=g&hvrand=8725083750186294282&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9011884&hvtargid=pla-1258810084698&psc=1" target="_blank">Amazon</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Eczema-Relief-Cream-Body-Wash-Eczema-Body-Wash-13-5-Fl-Oz-Bottle/274424851?wmlspartner=wlpa&selectedSellerId=0&wl13=2907&adid=22222222277274424851_117755028669_12420145346&wmlspartner=wmtlabs&wl0=&wl1=g&wl2=c&wl3=501107745824&wl4=pla-293946777986&wl5=9011884&wl6=&wl7=&wl8=&wl9=pla&wl10=8175035&wl11=local&wl12=274424851&wl13=2907&veh=sem_LIA&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMTumfIwlfXJTywNqNOcwHy4E-_xnLcg2eaqpAeFmT4b5UPL2W1WdFUaAmQLEALw_wcB&gclsrc=aw.ds"  target="_blank">Walmart</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href="https://www.target.com/p/eucerin-eczema-relief-cream-body-wash-with-gentle-cleanser-13-5-fl-oz/-/A-82867850?ref=tgt_adv_XS000000&AFID=google_pla_df&fndsrc=tgtao&DFA=71700000086347354&CPNG=PLA_Beauty%2BPersonal+Care%2BShopping_Traffic%7CBeauty_Ecomm_Beauty&adgroup=SC_Health%2BBeauty&LID=700000001170770pgs&LNM=PRODUCT_GROUP&network=g&device=c&location=9011884&targetid=pla-895876315184&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMSGQZXQ67IhzdZoAm2OLMhOWzipTyMPu8NalrYG9eIyKh4eDrZ9_H8aAhESEALw_wcB&gclsrc=aw.ds"  target="_blank">Target</a>
									</li>
								</ul>
								<ul className="link-target-button">
									<li>
										<a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-eczema-relief-cream-body-wash-13-5-oz-prodid-377723?skuid=377723&cgaa=QWxsb3dHb29nbGVUb0FjY2Vzc0NWU1BhZ2Vz&cid=ps_ski_pla&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMQQcgD3d3VSff47PPTv1DSje9IkNEI1uRN3PW7-omy2f3dK2hwHI88aAoWXEALw_wcB&gclsrc=aw.ds"  target="_blank">CVS</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-eczema-cream-body-wash/ID=300421357-product?ext=gooFY23_GOO_RET_RETAILDEMANDGEN_Performance+Max+-+Beauty_REV_SRC_PMAX_PMAX_NA_PMAX_ENG__pla_local&gclsrc=aw.ds&&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMTt5lQnIt9DjmGAqrUYEugBUsA38jXTWNeje9QTK0AlUds8JZEljdoaAs9mEALw_wcB"  target="_blank">Walgreens</a>
									</li>
								</ul>

								<ul className="dblarrows red">
									<li>
										<span>
											Soap-free, non-foaming, <nobr>non-drying</nobr>, non-irritating
										</span>
									</li>
									<li>
										<span>
											<span className="font-bold">Low pH formula</span> with
											ceramide and colloidal oatmeal (skin protectant) to help
											protect and soothe skin during bathing
										</span>
									</li>
								</ul>

								<div className="icon-content">
									<span className="icon-text">ENRICHED WITH:</span>
									<div class="img-wrapper">						
									<img
										src={imgOatmeal}
										alt="Circle icon representing Coilloidal oatmeal and circle icon representing Ceramide NP"
										className=""
									/>
									<span className="plus">+</span>
									<img
										src={imgCeramide}
										alt="Circle icon representing Ceramide NP"
										className=""
									/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="inner-centered-container">
				<hr></hr>
			</section>

			{/* LINK-BUTTON SECTION 2 */}
			<section className="inner-centered-container callout-with-links-bullets">
				<Container fluid>
					<Row>
						<Col sm={12} md={3} className={deviceSizes?.smUp ? "no-pr" : ""}>
							<img src={img1} alt="A tube of Eucerin Eczema relief cream" className="" />
						</Col>
						<Col sm={12} md={9} className={deviceSizes?.smUp ? "no-pr" : ""}>
							<h2>
								Eucerin<sup>&reg;</sup> Eczema Relief Cream
							</h2>
							<div className="links-wrap">
								<span className="font-bold red-text">Buy now at:</span>
								<br />
								<ul className="link-target-button">
									<li>
										<a rel="noopener noreferrer" href="https://www.amazon.com/Eucerin-Eczema-Relief-Cream-Eczema-Prone/dp/B00DEXA0LY/ref=sr_1_1_mod_primary_sns?crid=27JP55U7VJF9&keywords=Eczema+relief+cream&qid=1663948038&s=beauty&sbo=GLaw0Fx56FiNH%2FiZ%2B6XKiQ%3D%3D&sprefix=eczema+relief+cream%2Cbeauty%2C63&sr=1-1" target="_blank">Amazon</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href="https://www.walmart.com/ip/Eucerin-Eczema-Relief-Body-Creme-8-Oz/32239571?wmlspartner=wlpa&selectedSellerId=0&&adid=22222222227000000000&wl0=&wl1=g&wl2=c&wl3=42423897272&wl4=pla-51320962143&wl5=9011884&wl6=&wl7=&wl8=&wl9=pla&wl10=8175035&wl11=online&wl12=32239571&veh=sem&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMQFATEezC1CQyBQbDWNsQXp8LI3XpHeq9qXo7cDbfmFOWTGl_6FsRwaAhjeEALw_wcB&gclsrc=aw.ds
"  target="_blank">Walmart</a>
									</li>
									<li>
										<a  rel="noopener noreferrer" href="https://www.target.com/p/eucerin-eczema-relief-cream-8oz/-/A-14667195?ref=tgt_adv_XS000000&AFID=google_pla_df&fndsrc=tgtao&DFA=71700000086530542&CPNG=PLA_Beauty%2BPersonal+Care%2BShopping_Traffic_Local_Traffic%7CBeauty_Ecomm_Beauty&adgroup=SC_Health%2BBeauty&LID=700000001170770pgs&LNM=PRODUCT_GROUP&network=g&device=c&location=9011884&targetid=pla-894400546253&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMRmTgC2KLwA48MpjO4Dgphnn1LD75PMLv8ma1rFytLWmKIq3ZPq7qgaAl7iEALw_wcB&gclsrc=aw.ds"  target="_blank">Target</a>
									</li>
								</ul>
								<ul className="link-target-button">
									<li>
										<a rel="noopener noreferrer" href="https://www.cvs.com/shop/eucerin-eczema-relief-body-cream-5-oz-prodid-374477?skuid=374477&cgaa=QWxsb3dHb29nbGVUb0FjY2Vzc0NWU1BhZ2Vz&cid=ps_ski_pla&gclid=Cj0KCQjw39uYBhCLARIsAD_SzMQ8sFo1upXBo24hBjRJaV9e7GK7dsMj7suj-F_g0QHWS3ibRsdjEf0aAh2LEALw_wcB&gclsrc=aw.ds
"  target="_blank">CVS</a>
									</li>
									<li>
										<a rel="noopener noreferrer" href="https://www.walgreens.com/store/c/eucerin-eczema-relief-body-cream/ID=prod6208234-product?ext=gooFY23_GOO_RET_RETAILDEMANDGEN_Performance+Max+-+Beauty_REV_SRC_PMAX_PMAX_NA_PMAX_ENG__pla_local&gclsrc=aw.ds&&gclid=CjwKCAjw4c-ZBhAEEiwAZ105RVBXPSkgRGLzbBDC-v9BUYdN4CnlvrXdhIPX67ssqVNg2-_qxSMGuBoC6aoQAvD_BwE"  target="_blank">Walgreens</a>
									</li>
								</ul>

								<ul className="dblarrows red">
									<li>
										<span>
											With ceramide, colloidal oatmeal (skin protectant), and
											licorice root extract to <span className="font-bold">help soothe <nobr>red, irritated</nobr> skin</span>
										</span>
									</li>
									<li>
										<span>
											<span className="font-bold">Clinically proven</span> to
											reduce frequency of eczema flares on children's skin
										</span>
									</li>
									<li>
										<span>
											<span className="font-bold">4 out of 5 children</span>{" "}
											remained <nobr>flare-free</nobr> for 6 months
										</span>
									</li>
								</ul>

								<div className="icon-content">
									<span className="icon-text">ENRICHED WITH:</span>
							<div class="img-wrapper">								
								<img
										src={imgOatmeal}
										alt="Circle icon representing Licochalcone"
										className=""
									/>
									<span className="plus">+</span>
									<img
										src={imgLicochalcone}
										alt="Circle icon representing Ceramide NP"
										className=""
									/>
									<span className="plus">+</span>
									<img
										src={imgCeramide}
										alt="Circle icon representing Ceramide NP"
										className=""
									/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			{/* TREATMENT GOAL SECTION */}
			 <div className="inner-body-content">
				<section className="inner-centered-container treatment">
					<ProductTypeA
						categoryBgColor="#A70531"
						categoryName="Treatment Goals"
						categoryFontColor="#ffffff"
						productName="Treatment goals for eczema"
						productAlt="Patient on phone with Eczema flares on their elbow"
						productImage={img4}
					>
						<div className="functions">
							<p>
								Your doctor will review the treatment goals for your specific <nobr>eczema-prone</nobr> skin, but treatment goals often include:
							</p>
							<ul className="dblarrows red">
								<li>Reducing the frequency and number of flares</li>
								<li>
									Relieving symptoms (redness, dryness, itching, irritation)
								</li>
								<li>Identifying and managing triggers</li>
								<li>Using products that are <nobr>pH-optimized</nobr> to skin</li>
								<li>
									Improving hydration and maintaining moisture of the skin
								</li>
							</ul>
						</div>
					</ProductTypeA>
				</section>
			</div> 

			{/* QUESTION TO ASK */}

			<div className="inner-body-content grey-section">
				<section className="inner-centered-container">
					<ProductTypeB
						categoryBgColor="#A70531"
						categoryName="QUESTIONS TO ASK"
						categoryFontColor="#ffffff"
						productName="Questions to ask your doctor"
						productAlt="Male Doctor talking to patient while pointing at an ipad"
						productImage={img5}
					>
						<div className="functions">
							<ul className="dblarrows red">
								<li>I am still having flares. What can I do to reduce
flares?</li>
								<li>
								What can help my itching/irritation?
								</li>
								<li>My skin is so dry. What should I use to moisturize?</li>
								<li>How can I identify and help eliminate or reduce
my triggers?</li>
								<li>
								Can you help me understand what skin pH is and why
it's important for people with eczema?
								</li>
							</ul>
						</div>
					</ProductTypeB>
				</section>
			</div>

			{/* PH SCALE */}
			<section className="callouts-container gradient-bg section-ph-scale">
				<div className="inner-centered-container">
					<Row>
						<Col sm={12} md={12}>
							<TypeA
								className="center-v"
								headBgColor="#A70531"
								headName="pH AND ECZEMA"
								headFontColor="#ffffff"
								head1="Skin of eczema patients has a higher pH (is less acidic) than healthy skin."
								prodLabel="Skin pH and its impact on eczema-prone skin"
								phContent="The pH range for healthy skin is slightly acidic:"
								phNumber="4-6"
								prodImage={img6}
								productAlt="Scale of skin pH ranging from numbers 0 to 14. Acidic ranges from 0-3, Neutral ranges from 4-7, and Basic ranges from 9-14"
							>
								<p className="reg">
									Achieving a lower pH of your skin can help keep skin
									moisturized and limit the growth of harmful bacteria, which
									can enter the damaged skin barrier of skin with eczema.
								</p>
							</TypeA>
						</Col>
					</Row>
				</div>
			</section> 

			{/* HEALTHY HABITS */}

			<section className="callout-container fluid-section darkgray-gradient-bg">
				<div className="inner-centered-container">
					<Row>
						<Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""}  order={{ xs: 2, md: 1 }}>
							<ProductTypeC
								categoryBgColor="#A70531"
								categoryName="Healthy Habits"
								categoryFontColor="#ffffff"
								productName="Healthy habits to pHortify <span className='fbreak'>your <nobr>eczema-prone skin</nobr></span"
							>
								<div className="functions">
									<h2>
										Changing your bathing routine can help lower your skin’s
										pH
									</h2>
									<ul className="dblarrows white">
										<li>
											Typical bar soap can have a pH of <nobr>9-10</nobr>. Washing with
											alkaline bar soap can raise the pH of skin for several
											hours
										</li>
									</ul>
								</div>
							</ProductTypeC>
						</Col>
						<Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""}  order={{ xs: 1, md: 2 }}>
							<div className="cout-img">
								<img
									src={img7}
									alt="Woman apply lotion to shoulder area"
								/>
							</div>
						</Col>
					</Row>
				</div>
			</section>

			{/* THINGS TO DO SECTION */}

			<section className="callout-container inner-centered-container">
				<div className="inner-body-content">
					<Row>
						<Col sm={12} md={12} order={{ xs: 2, md: 1 }} className="">
							<TypeB
								className="icon-wrap"
								headName="Things to do"
								headFontColor="#A70531"
								quoteContent="Bathing and moisturizing before bed are especially helpful to allow </br> the skin to fully hydrate during your sleep."
							>
								<Container fluid>
									<Row>
										<Col sm={12} md={4} className="mobile">
											<img src={icon1} alt="red circle with shower head inside of it"></img>
											<p>
												Take a shower or bath for 5-10 minutes using lukewarm
												water (not hot) at least once a day
											</p>
										</Col>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon2} alt="red circle with bottle icon inside of it"></img>
											<p>
												Use a gentle body wash (low pH, no fragrances or dyes)
											</p>
										</Col>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon3} alt="red circle with a towel on a rack inside of it"></img>
											<p>
												Pat the skin with a towel, leaving it damp (apply
												topical medication if directed by your doctor)
											</p>
										</Col>
									</Row>
									<Row>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon4} alt="red circle with red water droplets inside of it"></img>
											<p>
												Liberally moisturize your
												<br />
												entire body immediately
											</p>
										</Col>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon5} alt="red cricle with a red clock inside of it"></img>
											<p>
												Use moisturizer throughout the day—whenever your skin
												feels dry or starts to itch
											</p>
										</Col>
									</Row>
								</Container>
							</TypeB>
						</Col>
					</Row>
				</div>
			</section>

			{/* THINGS NOT TO USE  SECTION */}

			<section className="callout-container inner-centered-container my-75 icon-section">
				<div className=" inner-body-content">
					<Row>
						<Col sm={12} md={12} order={{ xs: 2, md: 1 }} className="">
							<TypeB
								className="icon-wrap"
								headName="Things not to use"
								headFontColor="#A70531"
								quoteContent="Understand and manage your eczema triggers: allergens, overheating or<br/>sweating, emotional stress."
							>
								<Container fluid>
									<Row>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon6} alt="red circle with a hand covered in soap inside of it"></img>
											<p>
												Harsh cleaners and detergents, bubble bath, or hand
												sanitizers
											</p>
										</Col>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon7} alt="red cricle with a red sponge inside of it"></img>
											<p>
												Loofahs, scrubs, or other exfoliants that can scrape and
												irritate your skin
											</p>
										</Col>
										<Col sm={12} md={4}  className="mobile">
											<img src={icon8} alt="red circle with three red wavy lines inside of it"></img>
											<p>Hot tubs, saunas, or steam rooms</p>
										</Col>
									</Row>
								</Container>
							</TypeB>
						</Col>
					</Row>
				</div>
			</section>

			{/* SURFACE  ON SKIN */}

			<section className="callout-container fluid-section darkgray-gradient-bg">
				<div className="inner-centered-container">
					<Row>
						<Col md={12} lg={6} className={deviceSizes?.smUp ? "" : ""}  order={{ xs: 2, md: 1 }}>
							<ProductTypeC
								categoryFontColor="#ffffff"
								productName="On the surface of your skin is a protective layer called the acid mantle"
							>
								<div className="functions">
									<h2>
											What the acid mantle does:
									</h2>
									<ul className="dblarrows white">
										<li>Helps your skin maintain its moisture</li>
										<li>
											Acts as a barrier to harmful bacteria and other elements
											that may penetrate the skin
										</li>
									</ul>
								</div>
							</ProductTypeC>
						</Col>
						<Col md={12} lg={6} className={deviceSizes?.smUp ? "no-pr" : ""}   order={{ xs: 1, md: 2 }}>
							<div className="cout-img">
								<img
									src={img9}
									alt="Image of a woman with curcle dark hair turned to the side"
									className="image" 
								/>
							</div>
						</Col>
					</Row>
				</div>
			</section> 

			{/* ECZEMA PRONE SKIN */}
			<div className="inner-body-content">
				<section className="inner-centered-container eczema-prone">
					<ProductTypeB
						categoryBgColor="#fff"
						categoryFontColor="#ffffff"
						productName="Eczema-prone skin has a
						damaged skin barrier
						that can flare and result in a
						red, scaly, itchy rash"
						productAlt="graphic with pink layers detailing to the layers of skin"
						productImage={img8}
					>
						<div className="functions">
							<p>
								A damaged protective acid mantle{" "}
								<span className="font-bold">
									can allow harmful bacteria and other elements to enter the
									damaged skin
								</span>
								. It is more difficult to keep the skin moisturized.
							</p>
						</div>
					</ProductTypeB>
				</section>
			</div>

			<section className="inner-centered-container my-75">
				<hr></hr>
			</section>

		</Layout>
	);
}

export default HomePage;
