import React from "react";
import { Container, Row, Col } from "react-grid-system";

const ProductTypeB = ({
  categoryBgColor,
  categoryFontColor,
  categoryName,
  productName,
  productImage,
  productAlt,
  className,
  children,
  ...props
}) => {
  return (
    <div {...props} className="product-details">
      <Container fluid>
        <Row direction="row">
          <Col sm={12} md={5} className="no-px left" order={{ xs: 1, md: 1 }}>
            <img
              src={productImage}
              className="zoom-img"
              alt={productAlt ? productAlt : ""}
            />
          </Col>
          <Col sm={12} md={7} className="pl-50 right-col" order={{ xs: 2, md: 2 }}>
            <h3
              style={{
                backgroundColor: categoryBgColor,
                color: categoryFontColor,
              }}
              className="category-name"
            >
              {categoryName ? categoryName : ""}
            </h3>
            <h4
              className="product-name"
              dangerouslySetInnerHTML={{ __html: productName }}
            />
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductTypeB;
