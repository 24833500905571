import React from "react";
import { Container, Row, Col } from "react-grid-system";

function TypeA({
  headBgColor,
  headFontColor,
  headName,
  head1,
  prodLabel,
  prodLabelCss,
  prodImage,
  head1Css,
  regTextCss,
  className,
  phContent,
  phNumber,
  children,
  productAlt,

  ...props
}) {
  return (
    <div
      {...props}
      className={`cout-type-a ${className ? className : ""}`}
    >
      <h3
        style={{
          backgroundColor: headBgColor,
          color: headFontColor,
        }}
        className="category-name"
      >
        {headName}
      </h3>

      {prodLabel ? (
        <h4
          className={prodLabelCss}
          dangerouslySetInnerHTML={{ __html: prodLabel }}
        />
      ) : (
        ""
      )}
      {head1 ? (
        <h5 className={head1Css} dangerouslySetInnerHTML={{ __html: head1 }} />
      ) : (
        ""
      )}

        <Row>
          <Col
            sm={3}
            md={4}
            lg={4}
            className="ph-scale-wrapper"
            order={{ xs: 2, md: 2, lg:1 }}
          >
            <div className="upper-content">
                <Row>
                  <Col sm={6} lg={6} className="left-col">
                    {phContent}
                  </Col>
                  <Col sm={6} lg={6} className="big-num">
                    {phNumber}
                  </Col>
                </Row>
            </div>
            {children}
          </Col>
          <Col
            sm={12}
            md={8}
            lg={8}
            className="right-col"
            order={{ xs: 1, md: 1,  lg:2 }}
          >
            <img src={prodImage} alt={productAlt ? productAlt : ""} />
          </Col>
        </Row>
    </div>
    
  );
}

export default TypeA;
