import React from "react";
import { Container, Row, Col } from "react-grid-system";

const ProductTypeA = ({
  categoryBgColor,
  categoryFontColor,
  categoryName,
  productName,
  productImage,
  productAlt,
  className,
  children,
  ...props
}) => {
  return (
    <div {...props} className="product-details">
      <Container fluid>
        <Row direction="row">
          <Col
            sm={12}
            md={7}
            className="no-pl left-col pr-50"
            order={{ xs: 2, md: 1 }}
          >
            <h3
              style={{
                backgroundColor: categoryBgColor,
                color: categoryFontColor,
              }}
              className="category-name"
            >
              {categoryName}
            </h3>
            <h2
              className="product-name"
              dangerouslySetInnerHTML={{ __html: productName }}
            />
            {children}
          </Col>
          <Col
            sm={12}
            md={5}
            className="no-px right-col"
            order={{ xs: 1, md: 2 }}
          >
              <img
                src={productImage}
                className="zoom-img"
                alt={productAlt ? productAlt : ""}
              />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductTypeA;
