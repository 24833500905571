const appConfigs = {
  metaData: {
    SITE_NAME: ``,
    SITE_DEFAULT_DESCRIPTION: `Description!`,
    SITE_DEFAULT_KEYWORDS: ["Beiersdorf"],
    CURRENT_YEAR: new Date().getFullYear(),
  },
};

export default appConfigs;
